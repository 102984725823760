.loading-bar {
    height: 3px;
    background: gray;
    animation: width-keyframes 10s linear;
    animation-fill-mode: forwards;
}

@keyframes width-keyframes {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}