
.loadingspinner {
    margin: 1em auto;
    pointer-events: none;
    width: 2.5em;
    height: 2.5em;
    border: 0.4em solid transparent;
    border-color: #eee;
    border-top-color: #3E67EC;
    border-radius: 50%;
    -webkit-animation: loadingspin 1s linear infinite;
    animation: loadingspin 1s linear infinite;
}

@-webkit-keyframes loadingspin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingspin {
    100% {
        transform: rotate(360deg);
    }
}