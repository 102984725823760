dl {
    display: flex;
    flex-flow: row wrap;
    border: solid #d2d2d2;
    border-width: 1px 1px 0 0;
}
dt {
    flex-basis: 20%;
    padding: 2px 4px;
    background: #d2d2d2;
    text-align: right;
}
dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
    border-bottom: 1px solid #d2d2d2;
}

fieldset {
    margin: 0.2rem;
}

.blink-red svg {
    animation-name: updateIconFlashes;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

@keyframes updateIconFlashes {
    0% {color: red;}
    50% {color: white;}
    100% {color: red;}
}